import React from 'react';
import styled from '@emotion/styled';
import OnlineSvg from './svg/online_fagseminar.svg';
//import OnlinePng from './png/online_fagseminar.png';

const StyledLogoContainer = styled.div`
  svg {
    width: auto;
    height: auto;
    max-width: 33vw;
    min-width: 240px;
    max-height: 42vh;
    min-height: 334px;
  }
`;

const OnlineLogo = () => {
  return (
    <StyledLogoContainer>
      <OnlineSvg alt="Online fagseminar logo" />
    </StyledLogoContainer>
  );
};

export default OnlineLogo;
